var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":_vm.$t('report.generate-report')},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.reportLoading},on:{"click":_vm.requestNewReport}},[_vm._v(" "+_vm._s(_vm.$t('report.add-report-to-generation-queue'))+" ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"generateNewReportValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('report.reports.filters.report'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"reportType"}},[_vm._v(" "+_vm._s(_vm.$t('report.reports.filters.report'))+" ")]),_c('v-select',{attrs:{"id":"reportType","options":_vm.filteredReportTypes,"clearable":false,"searchable":false,"reduce":function (name) { return name.name; }},on:{"option:selected":_vm.reportTypeChanged},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(_vm.$t(("report.reports.types." + (option.name))))+" ")])}},{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("report.reports.types." + name))))])]}}],null,true),model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.reportFormControls.customer)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('report.reports.filters.customer'),"rules":_vm.reportFormControls.customerRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"customer"}},[_vm._v(" "+_vm._s(_vm.$t('report.reports.filters.customer'))+" ")]),_c('v-select',{attrs:{"id":"customer","options":_vm.customers,"reduce":function (name) { return name.id; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[(option.id)?_c('span',[_vm._v(" "+_vm._s(option.name)+", "+_vm._s(option.city)+" ")]):_vm._e()])}},{key:"option",fn:function(ref){
var name = ref.name;
var city = ref.city;
return [_c('span',[_vm._v(_vm._s(name)+", "+_vm._s(city))])]}}],null,true),model:{value:(_vm.reportFilters.customer),callback:function ($$v) {_vm.$set(_vm.reportFilters, "customer", $$v)},expression:"reportFilters.customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2598420699)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('report.reports.filters.month'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"month"}},[_vm._v(" "+_vm._s(_vm.$t('report.reports.filters.month'))+" ")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"month","config":_vm.configDateTimePicker,"name":_vm.$t('report.reports.filters.month')},model:{value:(_vm.reportFilters.month),callback:function ($$v) {_vm.$set(_vm.reportFilters, "month", $$v)},expression:"reportFilters.month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{attrs:{"no-body":""}},[_c('h4',{staticClass:"pt-2 pl-2 pb-1"},[_vm._v(" "+_vm._s(_vm.$t('report.reports.list-title'))+" ")]),_c('b-table',{ref:"refReportsTable",staticClass:"position-relative",attrs:{"items":_vm.reports,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('report.no-data-description')},scopedSlots:_vm._u([{key:"cell(type)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(("report.reports.types." + (data.item.type))))+" ")]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(("report.status." + (data.item.status))))+" ")]}},{key:"cell(customer)",fn:function(data){return [(data.item.customer)?_c('span',[_vm._v(" "+_vm._s(data.item.customer.name)+", "+_vm._s(data.item.customer.city)+" ")]):_vm._e()]}},{key:"cell(requestedBy)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.requestedBy.surname)+" "+_vm._s(data.item.requestedBy.name)+" ")]}},{key:"cell(generatedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.transformDatetimeToHumanReadablePretty(data.item.updatedAt))+" ")]}},{key:"cell(actions)",fn:function(data){return [(_vm.canDownload(data.item))?_c('div',{staticClass:"text-nowrap"},[(_vm.downloading)?_c('feather-icon',{attrs:{"icon":"LoaderIcon","size":"16"}}):_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"DownloadIcon","size":"16"},on:{"click":function($event){return _vm.download(data.item.id)}}})],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }